<template>
    <div>
      <div>
        <i :class="trigger.icon"></i> {{ trigger.label }}
      </div>
    
      <b-modal id="anniversary-modal" v-model="isShowModal" title="Anniversary" size="lg">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>How many days prior to the anniversary would you like to start this automation?</label>
              <input v-model="automation.options.anniversary" type="number" min="1" class="form-control" >
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Time to Send</label>
              <flat-pickr
                v-model="automation.options.send_at"
                :config="timePicker"
                class="form-control"
                placeholder="Pick a time"
              ></flat-pickr>
            </div>
            <div class="mt-2">
              <strong><span v-b-tooltip.hover title="You can edit this timezone in settings page.">Timezone: {{ settingTimezone }}</span></strong>
              <div>Current Time: {{ serverTime }}</div>
              <!-- <div class="mt-2">Local Timezone: {{ localTimeZone }}</div>
              <div>Local Time: {{ localTime }}</div> -->
            </div>
          </div>
        </div>
    
        <div class="form-group mt-3 small-checkbox">
          <b-form-checkbox v-model="automation.options.stop_on_reply" name="stop_on_reply">
            Stop on contact reply 
            <span v-b-tooltip.hover class="cursor-pointer" title="When someone replies to a message in the automation the remainder of the automation will be cancelled" style="vertical-align: middle;">
              <i  class="uil uil-question-circle"></i>
            </span>
          </b-form-checkbox>
        </div>

        <template v-slot:modal-footer>
          <b-button variant="primary" @click="save">Save</b-button>
        </template>
      </b-modal>
    </div>
    </template>
    
    <script>
    import moment from 'moment-timezone'
    export default {
      components: {
      },
    
      props: {
        automation: {
          type: Object,
          default: null,
        },
        blocks: {
          type: Array,
          default: null,
        },
        trigger: {
          type: Object,
          default: null,
        },
      },
    
      data() {
        return {
          isShowModal: false,
          timePicker: {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'G:i K',
          },
        }
      },
    
      computed: {
        user() {
          return this.$store.getters['auth/user']
        },
        localTimeZone() {
          let timezone = moment.tz.guess()
    
          return `(UTC${moment()
            .tz(timezone)
            .format('Z')}) ${timezone}`
        },
        localTime() {
          return moment().format('MMM D, YYYY hh:mm A')
        },
        serverTime() {
          let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()
    
          return moment()
            .tz(timezone)
            .format('MMM D, YYYY hh:mm A')
        },
        settingTimezone() {
          let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()
    
          return `(UTC${moment()
            .tz(timezone)
            .format('Z')}) ${timezone}`
        },
      },
    
      mounted() {
        this.automation.options = {
          ...{ anniversary: 1, campaigns: [], send_at: '09:00 AM' },
          ...this.automation.options
        }
        this.automation.keywords = []
    
        this.$parent.$on('showModal', this.showModal)
      },
    
      methods: {
        save() {
          this.isShowModal = false
        },
    
        showModal() {
          this.isShowModal = true
        },
      },
    }
    </script>
    
    <style lang="scss">
    #anniversary-modal .small-checkbox {
      .flatpickr-input {
        background: #FFF !important;
      }
    
      .custom-checkbox {
        padding-left: 30px;
        margin-bottom: 5px;
      }
    
      .custom-control-label:before, .custom-control-label:after {
        width: 20px;
        height: 20px;
        left: -30px;
        top: 0px;
      }
    
      .custom-control-input {
        width: 20px;
        height: 20px;
        top: -4px;
      }
    }
    </style>