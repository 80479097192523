<template>
<div>
  <div>
    <i :class="trigger.icon"></i> {{ trigger.label }}
    <span v-if="keywordList" class="keywords-span">( {{ keywordList }} )</span>
  </div>

  <b-modal id="date-modal" v-model="isShowModal" title="Keywords" size="lg">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Keywords</label>
          <multiselect
            v-model="automation.keywords"
            :options="keywords"
            :multiple="true"
            track-by="id"
            placeholder="Select keywords"
            label="name"
            :taggable="true"
            @tag="addKeyword"
          ></multiselect>
        </div>
        <div class="form-group">
          <span class="pr-2" style="font-weight:600;">OFF</span>
          <b-form-checkbox
            v-model="automation.options.any_part"
            name="check-button"
            switch
            inline
          >
            ON 
          </b-form-checkbox>
          <p class="pt-4">
            Turn on the trigger to start the automation when the keyword is in any part of the message
          </p>
        </div>
        <div class="form-group small-checkbox">
          <b-form-checkbox v-model="automation.options.stop_on_reply" name="stop_on_reply">
            Stop on contact reply 
            <span v-b-tooltip.hover class="cursor-pointer" title="When someone replies to a message in the automation the remainder of the automation will be cancelled" style="vertical-align: middle;">
              <i  class="uil uil-question-circle"></i>
            </span>
          </b-form-checkbox>
        </div>

      </div>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      keywords: [],
      keywordSelected: [],
    }
  },

  computed: {
    keywordList() {
      if (this.keywordSelected)
        return this.keywordSelected.map(item => item.name).join(', ');
      return ''
    },
  },

  mounted() {
    this.$parent.$on('showModal', this.showModal)
    this.keywordSelected = this.automation.keywords
    this.checkStaticCoupon()
  },

  methods: {
    checkStaticCoupon() {
      const params = {
        keywords: this.automation.keywords.map(item => item.id)
      }
      this.$store
        .dispatch('keyword/hasFeatures', params)
    },

    save() {
      if (!this.automation.keywords || !this.automation.keywords.length)
      {
        Vue.$toast.open({
          message: 'Keywords can\'t be empty',
          type: 'error',
          duration: 3000,
        })
        return false;
      }
      this.keywordSelected = this.automation.keywords
      this.isShowModal = false
      this.checkStaticCoupon()
    },

    showModal() {
      this.getKeywords()
      this.isShowModal = true
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywords', { type: 'automation' })
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },

    addKeyword(newKeyword) {
      const param = {
        name: newKeyword,
        type: 'automation'
      }
      this.$store
        .dispatch('keyword/create', param)
        .then((res) => {
          if (res)
          {
            if (!this.automation.keywords)
              this.automation.keywords = []
            this.automation.keywords.push(res.data.data)
            this.getKeywords();
          } else {
          }
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .keywords-span {
    display: block;
    font-size: 13px;
    font-weight: normal;
  }
</style>

<style lang="scss">
#date-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>